html {
    height: 100%;
}

body{
    height: 100%;
}

/* default buttons settings */


.gh-bk-default.buttonPrimary {
    background-color: #1AB394 !important;
    color           : #FFFFFF;
    box-shadow      : none;
}

.gh-bk-default.buttonSecundary {
    background-color: #FFFFFF !important;
    color           : #686C6D !important;
    box-shadow      : none;
}

.gh-bk-default.progBar.indeterminate {
    background-color: #E3E3E3 !important;
}

.gh-bk-default.barLinea{
    background-color: #1AB394 !important;
}

.gh-bk-default.buttonLink {
    background-color: transparent;
    color           : #212121;
    box-shadow      : none;
}

.gh-bk-default.buttonPrimary:disabled {
    background-color: #c3c3c3 !important;
    color           : #FFFFFF !important;
    box-shadow      : none !important;
}

.gh-bk-default.divFooter{
    background-color: #FFFFFF !important;
    border-top: 1px solid #E3E3E3 !important;
}

.gh.bk-default.bodLine{
    border-top: 2px solid #E3E3E3 !important;

}

.gh-bk-default.divCar{
    background-color: #ffffff !important;
    box-shadow: 0px 0px 8px #00000029;
}

.gh-bk-default.buttonDanger {
    background-color: #FF4855;
    color           : #FFFFFF;
    box-shadow      : none;
}
.gh-bk-default.buttonPhone{
    background-color: #FFFFFF !important;
    color: #212121 !important;
    box-shadow: 0px 2px 8px #F4B92A52;
}

.gh-bk-default.backPhone{
    background-color: #212121;
    color: #FFFFFF;
}

/*color default settings */


.gh-cl-default.textTime{
    margin-top: 30px !important
}

.gh-cl-default.txtCancel{
    color : #686C6D !important;
}

.gh-cl-default.callfront{
    color :#363636 !important;
    border: 1px solid #363636 !important;
}

.gh-cl-default.inpwhite{
    border-bottom: 1px solid #707070 !important;
    
}

.gh-cl-default.bodLine{
    border-top: 2px solid #E3E3E3 !important;

}

.gh-cl-default.inpwhite:focus{
    border-bottom: 1px solid #707070 !important;
    box-shadow: 0 1px 0 0 #707070 !important
}

.gh-cl-default.textLabel{
    color: #686C6D !important;

}

.gh-cl-default.imgmap{
    filter: invert(1);
 }

/* dark buttons settings */
.gh-bk-dark.buttonPrimary {
    background-color: #212121;
    color           : #000000 !important;
    box-shadow      : none;
}

.gh-bk-dark.buttonSecundary {
    background-color: #212121;
    color           : #F4F4F4;
    box-shadow      : none;
}

.gh-bk-dark.buttonLink {
    background-color: transparent;
    color           : #f4f4f4;
    box-shadow      : none;
}

.gh-bk-dark.buttonPrimary:disabled {
    background-color: #363636 !important;
    color           : #999999 !important;
    box-shadow      : none !important;
}

.gh-bk-dark.divFooter{
    background-color: #212121 !important;
    border-top: 1px solid #686C6D !important;
}

.gh.bk-dark.bodLine{
    border-top: 2px solid #686C6D !important;
}

.gh-bk-dark.divCar{
    background-color: #2C2C2C !important;
    box-shadow: 0px 0px 8px #00000029;
}

.gh-bk-dark.buttonDanger {
    background-color: #FF4855;
    color           : #F4F4F4;
    box-shadow      : none;
}

.gh-bk-dark.buttonGrey{
    background-color: #686C6D !important;
    color           : #F4F4F4;
    box-shadow      : none;
}

.gh-bk-dark.buttonPhone{
    background-color: #2C2C2C !important;
    color: #f4f4f4 !important;
    box-shadow: 0px 2px 8px #F4B92A52 ;
}

.gh-bk-dark.backPhone{
    background-color: #686C6D;
    color: #212121 !important;
}


/*Color  dark settings  */
.gh-cl-dark.textwhite{
    color :#F4F4F4 !important;
}

.gh-cl-dark.bodLine{
    border-top: 2px solid #686C6D !important;

}

.gh-cl-dark.callfront{
    color :#F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
}

.gh-cl-dark.txtCancel{
    color : #C3C3C3 !important;
}

.gh-cl-dark.txtInfo{
    color :#F4F4F4 !important;
}

.gh-cl-dark.inpwhite{
    color:#f4f4f4 !important ;
    border-bottom: 1px solid #707070!important
}

.gh-cl-dark.inpwhite:focus{
    color: #F4F4F4 !important;
    border-bottom: 1px solid #707070 !important;
    box-shadow: 0 1px 0 0 #707070 !important
}

.gh-cl-dark.textLabel{
    color: #686C6D !important;
}

.gh-cl-dark.textLabel.active{
    color: #f4f4f4 !important;
}





// .gh-cl-dark.inpwhite:active{
//     box-shadow: 0 0.063rem 0 0 #888484 
// }


/* Progress bar dark    */
.gh-bk-dark.progBar.indeterminate {
    background-color: #212121 !important;
}


/* light buttons settings */
.gh-bk-light.buttonPrimary {
    background-color: #212121 ;
    color           : #FFFFFF ;
    box-shadow      : none;
}

.gh-bk-light.buttonSecundary {
    background-color: #ffffff !important;
    color           : #686C6D !important;
    box-shadow      : none;
}

.gh-bk-light.buttonLink {
    background-color: transparent;
    color           : #212121;
    box-shadow      : none;
}

.gh-bk-light.buttonPrimary:disabled {
    background-color: #c3c3c3 !important;
    color           : #FFFFFF !important;
    box-shadow      : none !important;
}

.gh-bk-light.divFooter{
    background-color: #FFFFFF !important;
    border-top: 1px solid #E3E3E3 !important;
}


.gh-bk-light.divCar{
    background-color: #FFFFFF !important;
    box-shadow: 0px 0px 8px #00000029;
}

.gh-bk-light.buttonDanger {
    background-color: #FF4855;
    color           : #FFFFFF;
    box-shadow      : none;
}

.gh-bk-light.buttonGrey{
    background-color: #212121 !important;
    color           : #FFFFFF;
    box-shadow      : none;
}

.gh-bk-light.buttonPhone{
    background-color: #FFFFFF !important;
    color: #212121 !important ;
    box-shadow: 0px 2px 8px #ec0303 ;
}

.gh-bk-light.backPhone{
    background-color: #686C6D;
    color: #FFFFFF;
}

/* Color ligth settings  */

.gh-cl-light.bodLine{
    border-top: 2px solid #E3E3E3 !important;

}

.gh-cl-light.callfront{
    color :#363636 !important;
    border: 1px solid #363636 !important;
}

.gh-cl-light.txtCancel{
    color : #686C6D !important;
}


.gh-cl-light.inpwhite{
    border-bottom: 1px solid #707070 !important;
}

.gh-cl-light.inpwhite:focus{
    border-bottom: 1px solid #707070 !important;
    box-shadow: 0 1px 0 0 #707070 !important;
}

.gh-cl-light.textLabel{
    color: #686C6D !important;

}

.gh-cl-light.imgmap{
    filter: invert(1);
 }



/* Progress bar light */

.gh-bk-light.barLinea{
    background-color: #000000;
}

.gh-bk-light.progress .progBar {
    background-color: #E3E3E3 !important;
}

/* filters svg */
.svg-color-212121 {
    filter: invert(13%) sepia(1%) saturate(1356%) hue-rotate(21deg) brightness(94%) contrast(98%);
}

.svg-color-FD0D1B {
    filter: invert(10%) sepia(94%) saturate(6229%) hue-rotate(353deg) brightness(114%) contrast(98%);
}

.svg-color-FD9726 {
    filter: invert(77%) sepia(23%) saturate(6787%) hue-rotate(341deg) brightness(101%) contrast(98%);
}

.svg-color-FFCC21 {
    filter: invert(91%) sepia(30%) saturate(3593%) hue-rotate(337deg) brightness(101%) contrast(102%);
}

.svg-color-0A23FB {
    filter: invert(14%) sepia(100%) saturate(7417%) hue-rotate(243deg) brightness(93%) contrast(117%);
}

.svg-color-FD28FC {
    filter: invert(30%) sepia(77%) saturate(5486%) hue-rotate(287deg) brightness(114%) contrast(102%);
}

.svg-color-969696 {
    filter: invert(63%) sepia(2%) saturate(33%) hue-rotate(314deg) brightness(93%) contrast(96%);
}

.svg-color-A51C0E {
    filter: invert(17%) sepia(79%) saturate(2679%) hue-rotate(353deg) brightness(92%) contrast(99%);
}

.svg-color-E69239 {
    filter: invert(63%) sepia(35%) saturate(907%) hue-rotate(347deg) brightness(95%) contrast(89%);
}

.svg-color-F4B92A {
    filter: invert(62%) sepia(90%) saturate(385%) hue-rotate(2deg) brightness(104%) contrast(93%);
}

.svg-color-1AB394 {
    filter: invert(55%) sepia(24%) saturate(6502%) hue-rotate(133deg) brightness(100%) contrast(80%);
}

.svg-color-3C74D6 {
    filter: invert(38%) sepia(99%) saturate(576%) hue-rotate(185deg) brightness(90%) contrast(90%);
}

.svg-color-674EA6 {
    filter: invert(37%) sepia(7%) saturate(5754%) hue-rotate(218deg) brightness(85%) contrast(83%);
}

.svg-color-A74F79 {
    filter: invert(34%) sepia(4%) saturate(7455%) hue-rotate(281deg) brightness(108%) contrast(76%);
}

.svg-color-6AA84E {
    filter: invert(58%) sepia(21%) saturate(5200%) hue-rotate(74deg) brightness(107%) contrast(45%);
}

.svg-color-9825FB {
    filter: invert(22%) sepia(93%) saturate(2715%) hue-rotate(264deg) brightness(93%) contrast(113%);
}

.svg-color-404040 {
    filter: invert(23%) sepia(4%) saturate(24%) hue-rotate(360deg) brightness(98%) contrast(92%);
}

.svg-color-5B0F04 {
    filter: invert(14%) sepia(30%) saturate(3389%) hue-rotate(341deg) brightness(95%) contrast(111%);
}

.svg-color-B25D16 {
    filter: invert(32%) sepia(95%) saturate(509%) hue-rotate(348deg) brightness(107%) contrast(92%);
}

.svg-color-BF8F1F {
    filter: invert(54%) sepia(77%) saturate(463%) hue-rotate(4deg) brightness(92%) contrast(88%);
}

.svg-color-264F13 {
    filter: invert(23%) sepia(11%) saturate(4934%) hue-rotate(63deg) brightness(88%) contrast(85%);
}

.svg-color-1B4682 {
    filter: invert(22%) sepia(84%) saturate(638%) hue-rotate(181deg) brightness(98%) contrast(101%);
}

.svg-color-1F124D {
    filter: invert(5%) sepia(69%) saturate(7317%) hue-rotate(257deg) brightness(70%) contrast(94%);
}

.svg-color-4B102F {
    filter: invert(9%) sepia(95%) saturate(1466%) hue-rotate(301deg) brightness(94%) contrast(99%);
}

.svg-color-000000 {
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg) brightness(102%) contrast(102%);
}

.svg-color-EF9A9A {
    filter: invert(92%) sepia(17%) saturate(2329%) hue-rotate(297deg) brightness(106%) contrast(87%);
}

.svg-color-FFCC80 {
    filter: invert(99%) sepia(91%) saturate(4531%) hue-rotate(304deg) brightness(106%) contrast(105%);
}

.svg-color-FDF5CC {
    filter: invert(86%) sepia(5%) saturate(2043%) hue-rotate(339deg) brightness(112%) contrast(106%);
}

.svg-color-C7DAFA {
    filter: invert(82%) sepia(12%) saturate(910%) hue-rotate(190deg) brightness(104%) contrast(96%);
}

.svg-color-C5E1A5 {
    filter: invert(91%) sepia(5%) saturate(1782%) hue-rotate(41deg) brightness(94%) contrast(94%);
}

.svg-color-E8D2DC {
    filter: invert(91%) sepia(20%) saturate(248%) hue-rotate(293deg) brightness(92%) contrast(98%);
}

.svg-color-E3E3E3 {
    filter: invert(100%) sepia(1%) saturate(1452%) hue-rotate(275deg) brightness(109%) contrast(78%);
}

.svg-color-E06767 {
    filter: invert(62%) sepia(19%) saturate(5367%) hue-rotate(321deg) brightness(99%) contrast(77%);
}

.svg-color-E42527 {
    filter: invert(25%) sepia(52%) saturate(4260%) hue-rotate(342deg) brightness(88%) contrast(103%);
}

.svg-color-F8B36B {
    filter: invert(80%) sepia(62%) saturate(688%) hue-rotate(313deg) brightness(108%) contrast(94%);
}

.svg-color-FED865 {
    filter: invert(88%) sepia(18%) saturate(1421%) hue-rotate(336deg) brightness(103%) contrast(104%);
}

.svg-color-6AA84E {
    filter: invert(66%) sepia(10%) saturate(2198%) hue-rotate(57deg) brightness(89%) contrast(82%);
}

.svg-color-A3C2F4 {
    filter: invert(74%) sepia(48%) saturate(2992%) hue-rotate(192deg) brightness(117%) contrast(91%);
}

.svg-color-C4B1F7 {
    filter: invert(83%) sepia(41%) saturate(2186%) hue-rotate(195deg) brightness(96%) contrast(101%);
}

.svg-color-E2D9FF {
    filter: invert(82%) sepia(16%) saturate(785%) hue-rotate(206deg) brightness(103%) contrast(102%);
}

.svg-color-FFA5D0 {
    filter: invert(98%) sepia(84%) saturate(3258%) hue-rotate(281deg) brightness(98%) contrast(105%);
}

.svg-color-969696 {
    filter: invert(69%) sepia(0%) saturate(2478%) hue-rotate(345deg) brightness(86%) contrast(90%);
}

.svg-color-FF8600 {
    filter: invert(55%) sepia(78%) saturate(2560%) hue-rotate(1deg) brightness(103%) contrast(105%);
}

.svg-color-FFC50F {
    filter: invert(88%) sepia(20%) saturate(4656%) hue-rotate(345deg) brightness(101%) contrast(105%);
}

.svg-color-1BB394 {
    filter: invert(52%) sepia(99%) saturate(349%) hue-rotate(118deg) brightness(91%) contrast(91%);
}

.svg-color-589AFF {
    filter: invert(56%) sepia(13%) saturate(5877%) hue-rotate(195deg) brightness(101%) contrast(101%);
}

.svg-color-B45FFF {
    filter: invert(69%) sepia(73%) saturate(6119%) hue-rotate(240deg) brightness(99%) contrast(104%);
}

.svg-color-FF4EA3 {
    filter: invert(55%) sepia(99%) saturate(3519%) hue-rotate(304deg) brightness(102%) contrast(101%);
}

.svg-color-BF8F1F {
    filter: invert(61%) sepia(10%) saturate(6872%) hue-rotate(11deg) brightness(95%) contrast(76%);
}